import TeamPage from "../team/team";
import { Typography } from "@mui/material";
import "./home.css";
import Translate from "../resources";

function Home() {
  return (
    <div className="container-fluid">
      <div className="row mt-4 mb-4 p-0">
        <Typography
          sx={{
            textAlign: "center",
            color: "#846d57",
            fontSize: "18px",
            fontFamily: "'Roboto', sans-serif",
          }}
        >
          DUODENT;
          <br />
          <br />
          {Translate("introductionText")}
          <br />
          <br />"{Translate("slogan")}"
        </Typography>
      </div>

      <div className="row mb-4 justify-content-center">
        <TeamPage></TeamPage>
      </div>

      <div className="row mt-4 p-2 duodentTextSection justify-content-center text-center rounded-4">
        <div className="row justify-content-center text-center">
          <p className="addresslink fw-bolder">
            {Translate("contactTextDirection")}
          </p>
        </div>
        <div className="row justify-content-center text-center">
          <a
            className="addresslink fw-bolder"
            href="https://goo.gl/maps/RpGXB4CoVvYyTaZj7"
          >
            İçerenköy Mahallesi, Çayır Caddesi, No:10/A 34752
            Ataşehir/İstanbul/Türkiye
          </a>
        </div>

        <div className="col mt-1">
          <p className="m-1 duodentText">
            <em>{Translate("inweek")}:</em> 09:30 - 19:00
          </p>
          <p className="m-1 duodentText">
            <em>{Translate("saturday")}:</em> 09:30 - 18:00
          </p>
          <p className="m-1 duodentText">
            <em>{Translate("sunday")}:</em>
            <b> {Translate("closed")}</b>
          </p>
        </div>
      </div>

      <div className="row mt-4 mb-4 justify-content-center">
        <h3 className="text-center text-nowrap fw-bolder header">
          {Translate("treatments")}
        </h3>
        <p className="text-center fw-bolder header mt-4 mb-4">
          {Translate("oralAndMaxillofacialSurgery")}
        </p>
        <ul className="list-group list-group-flush align-items-center">
          <li className="list-group-item">
            {Translate("treatmentTextImplant")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextAllOnFour")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextAllOnSix")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextExtract")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextSinusLifting")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentText20Years")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextApicalResection")}
          </li>
        </ul>

        <p className="text-center fw-bolder header mt-4 mb-4">
          {Translate("gumTreatments")} - {Translate("periodontology")}
        </p>
        <ul className="list-group list-group-flush align-items-center justify-content-center">
          <li className="list-group-item">
            {Translate("treatmentTextTartarCleaning")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextGingivitis")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextBoneErosion")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextGumRecession")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextToothSensitivity")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextLaserGum")}
          </li>
          <li className="list-group-item">
            {Translate("tratmentTextGummySmile")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextPinkAesthetics")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextCrownLengthening")}
          </li>
        </ul>

        <p className="text-center fw-bolder header mt-4 mb-4">
          {Translate("restorativeAndEndodontics")}
        </p>
        <ul className="list-group list-group-flush align-items-center justify-content-center">
          <li className="list-group-item">
            {Translate("treatmentTextRootCanal")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextRetreatment")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextPeriapicalLesions")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextDevitalBleaching")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextFracturedTooth")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextCompositeVeneers")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextCompositeFilling")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextCervicalFilling")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextAestheticFilling")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextToothWhitening")}
          </li>
        </ul>

        <p className="text-center fw-bolder header mt-4 mb-4">
          {Translate("protetic")}
        </p>
        <ul className="list-group list-group-flush align-items-center justify-content-center">
          <li className="list-group-item">
            {Translate("treatmentTextZirconium")}
          </li>
          <li className="list-group-item">{Translate("treatmentTextMetal")}</li>
          <li className="list-group-item">{Translate("treatmentTextEmax")}</li>
          <li className="list-group-item">
            {Translate("treatmentTextPorcelain")}
          </li>
          <li className="list-group-item">{Translate("treatmentTextInlay")}</li>
          <li className="list-group-item">
            {Translate("treatmentTextImplantProsthetic")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextPrecise")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextSmileDesign")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextHybrid")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextBruxism")}
          </li>
          <li className="list-group-item">{Translate("treatmentTextJaw")}</li>
        </ul>

        <p className="text-center fw-bolder header mt-4 mb-4">
          {Translate("pedodonticsDetailed")} - {Translate("pedodontics")}
        </p>
        <ul className="list-group list-group-flush align-items-center justify-content-center">
          <li className="list-group-item">
            {Translate("treatmentTextPediatric")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextProtective")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextToothpaste")}
          </li>
          <li className="list-group-item">{Translate("treatmentTextMilk")}</li>
          <li className="list-group-item">
            {Translate("treatmentTextProtectiveOrthodontic")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextGeneralAnesthesia")}
          </li>
        </ul>

        <p className="text-center fw-bolder header mt-4 mb-4">
          {Translate("orthodontics")}
        </p>
        <ul className="list-group list-group-flush align-items-center justify-content-center">
          <li className="list-group-item">
            {Translate("treatmentTextOrthodontic")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextInvisalign")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextOrthognathic")}
          </li>
          <li className="list-group-item">
            {Translate("treatmentTextSkeletal")}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Home;
