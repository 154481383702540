import React from 'react';
import Translate from '../resources';
import { DoctorInfo } from './DoctorInfo';

interface ResumeProps {
  doctorInfo: DoctorInfo;
}



const Resume: React.FC<ResumeProps> = ({ doctorInfo }) => {
  const {
    name,
    title,
    specialization,
    isPartner,
    imagePath,
    generalInfo,
    treatments,
    academicWork,
    seminars
  } = doctorInfo;

  return (
    <div className="container-fluid">
      <div className="row mt-4 align-items-center justify-content-center">
        <img
          className="img-fluid rounded-circle resumeimage"
          src={`/team-photos/${imagePath}`}
          alt={name}
          loading="lazy"
        />
      </div>
      
      <p className="resumeheader mt-2 text-center text-nowrap fw-bolder header">
        {title} {name}
      </p>
      
      <p className="text-center text-nowrap fst-italic resumecontent">
        {Translate(specialization)}
        {isPartner && ` - ${Translate("partner")}`}
      </p>

      <div className="row m-1">
        <p className="resumecontent header">
          {Translate(generalInfo)}
        </p>

        <div className="row mt-2 mb-2">
          <p className="resumeheader text-nowrap fw-bolder header">
            {Translate("resumeTextTreatments")}
          </p>
          <ul className="ms-5">
            {treatments.map((treatment, index) => (
              <p key={index} className="m-1 resumecontent">
                <li>{Translate(treatment)}</li>
              </p>
            ))}
          </ul>
        </div>

        <div className="row mt-2 mb-2">
          <p className="resumeheader text-nowrap fw-bolder header">
            {Translate("resumeTextAcedemic")}
          </p>
          <ul className="ms-5">
            {academicWork.map((work, index) => (
              <p key={index} className="m-1 resumecontent">
                <li>{Translate(work)}</li>
              </p>
            ))}
          </ul>
        </div>

        <div className="row mt-2 mb-2">
          <p className="resumeheader text-nowrap fw-bolder header">
            {Translate("resumeTextSeminars")}
          </p>
          <ul className="ms-5">
            {seminars.map((seminar, index) => (
              <p key={index} className="m-1 resumecontent">
                <li>{Translate(seminar)}</li>
              </p>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Resume; 