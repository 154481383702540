"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.gorkemData = void 0;
exports.gorkemData = {
    name: "Görkem Güner",
    title: "Dt.",
    specialization: "endodontics",
    isPartner: true,
    imagePath: "gorkem_guner_300x300.webp",
    generalInfo: "resumeTextGorkemGunerGeneral",
    treatments: [
        "resumeTextGorkemGunerTreatment1",
        "resumeTextGorkemGunerTreatment2",
        "resumeTextGorkemGunerTreatment3",
        "resumeTextGorkemGunerTreatment4",
        "resumeTextGorkemGunerTreatment5",
        "resumeTextGorkemGunerTreatment6"
    ],
    academicWork: [
        "resumeTextGorkemGunerAcademic1"
    ],
    seminars: [
        "resumeTextGorkemGunerSeminars1",
        "resumeTextGorkemGunerSeminars2",
        "resumeTextGorkemGunerSeminars3",
        "resumeTextGorkemGunerSeminars4",
        "resumeTextGorkemGunerSeminars5",
        "resumeTextGorkemGunerSeminars6",
        "resumeTextGorkemGunerSeminars7",
        "resumeTextGorkemGunerSeminars8",
        "resumeTextGorkemGunerSeminars9",
        "resumeTextGorkemGunerSeminars10",
        "resumeTextGorkemGunerSeminars11",
        "resumeTextGorkemGunerSeminars12"
    ]
};
