"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ezgiData = void 0;
exports.ezgiData = {
    name: "Ezgi Kaya",
    title: "Dt.",
    specialization: "orthodontics",
    isPartner: false,
    imagePath: "ezgi_kaya_300x300.webp",
    generalInfo: "resumeTextEzgiKayaGeneral",
    treatments: [
        "resumeTextEzgiKayaTreatment1",
        "resumeTextEzgiKayaTreatment2",
        "resumeTextEzgiKayaTreatment3",
        "resumeTextEzgiKayaTreatment4",
        "resumeTextEzgiKayaTreatment5"
    ],
    academicWork: [
        "resumeTextEzgiKayaAcademic1"
    ],
    seminars: [
        "resumeTextEzgiKayaSeminars1",
        "resumeTextEzgiKayaSeminars2",
        "resumeTextEzgiKayaSeminars3",
        "resumeTextEzgiKayaSeminars4",
        "resumeTextEzgiKayaSeminars5",
        "resumeTextEzgiKayaSeminars6",
        "resumeTextEzgiKayaSeminars7",
        "resumeTextEzgiKayaSeminars8",
        "resumeTextEzgiKayaSeminars9",
        "resumeTextEzgiKayaSeminars10",
        "resumeTextEzgiKayaSeminars11"
    ]
};
