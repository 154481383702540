"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selenaData = void 0;
exports.selenaData = {
    name: "Selena Su Denizer",
    title: "Dt.",
    specialization: "pedodontics",
    isPartner: false,
    imagePath: "selena_su_denizer_300x300.webp",
    generalInfo: "resumeTextSelenaSuDenizerGeneral",
    treatments: [
        "resumeTextSelenaSuDenizerTreatment1",
        "resumeTextSelenaSuDenizerTreatment2",
        "resumeTextSelenaSuDenizerTreatment3",
        "resumeTextSelenaSuDenizerTreatment4",
        "resumeTextSelenaSuDenizerTreatment5",
        "resumeTextSelenaSuDenizerTreatment6"
    ],
    academicWork: [
        "resumeTextSelenaSuDenizerAcademic1"
    ],
    seminars: [
        "resumeTextSelenaSuDenizerSeminars1",
        "resumeTextSelenaSuDenizerSeminars2",
        "resumeTextSelenaSuDenizerSeminars3",
        "resumeTextSelenaSuDenizerSeminars4",
        "resumeTextSelenaSuDenizerSeminars5",
        "resumeTextSelenaSuDenizerSeminars6"
    ]
};
