"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ayseData = void 0;
exports.ayseData = {
    name: "Ayşe Saatman Yıldız",
    title: "Dr. Dt.",
    specialization: "periodontology",
    isPartner: true,
    imagePath: "ayse_saatman_yildiz_300x300.webp",
    generalInfo: "resumeTextAyseYildizGeneral",
    treatments: [
        "resumeTextAyseYildizTreatment1",
        "resumeTextAyseYildizTreatment2",
        "resumeTextAyseYildizTreatment3",
        // ... rest of treatments
    ],
    academicWork: [
        "resumeTextAyseYildizAcademic1",
        "resumeTextAyseYildizAcademic2",
        // ... rest of academic work
    ],
    seminars: [
        "resumeTextAyseYildizSeminars1",
        "resumeTextAyseYildizSeminars2",
        // ... rest of seminars
    ]
};
